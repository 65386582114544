.AnomaliesFooter {
  width: 100%;
  height: 30px;
  padding: 2px;
  position: fixed;
  bottom: 0;
  z-index: 10;
  color: #999999;
  background-color: #404040;
  /* background-color: #141414; */
  text-align: center;
  border-top: 1px solid #d9d9d9;
}

.AnomaliesFooter-text {
  font-weight: 300;
}
