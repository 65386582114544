/* - - - - - - - - - - CONTAINER | WINGS - - - - - - - - - - */
#market-views-container,
#education-container,
#analytic-toolkit-container,
#community-container {
  cursor: pointer;
}
#market-views-title,
#education-title,
#analytic-toolkit-title,
#community-title {
  font-weight: 400;
}

/* - - - - - - - - - - DIAL TEXTS - - - - - - - - - - */
/* svg.LandingPage-dial text[id*='dial-text'] tspan {
  font-weight: 400;
} */

#dial-text-market-views:hover,
#dial-text-anomalies tspan:hover,
#dial-text-volatility tspan:hover,
#dial-text-flows tspan:hover,
#dial-text-gamma tspan:hover,
#dial-text-spreads tspan:hover,
#dial-text-seasonality tspan:hover,
#dial-text-portfolio tspan:hover,
#dial-text-timing tspan:hover,
#dial-text-economy tspan:hover,
#dial-text-sectors tspan:hover,
#dial-text-calendar tspan:hover,
#dial-text-cot tspan:hover {
  transition: all 250ms ease-in-out;
  -webkit-transition: all 250ms ease-in-out;
}

/* active */
#dial-text-market-views:hover,
#dial-text-anomalies tspan:hover
#dial-text-seasonality tspan:hover,
#dial-text-flows tspan:hover,
#dial-text-volatility tspan:hover,
#dial-text-gamma tspan:hover
{
  cursor: pointer;
  fill: #1890ff;
  /* font-size: 0.34rem; */
}

/* not active yet */
#dial-text-timing tspan:hover,
#dial-text-portfolio tspan:hover,
#dial-text-economy tspan:hover,
#dial-text-sectors tspan:hover,
#dial-text-calendar tspan:hover,
#dial-text-cot tspan:hover,
#dial-text-spreads tspan:hover
{
  cursor: not-allowed;
  fill: #333;
  /* font-size: 0.34rem; */
}
