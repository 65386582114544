.PrvActionableAnomalyEvent {
  margin-top: 1.5rem;
}
.PrvActionableAnomalyEvent-heading {
  font-size: 1.1rem;
  font-weight: 400;
  /* background-color: #ddd; */
}
.PrvActionableAnomalyEvent-actionable-yes {
  color: #1890ff;
}
.PrvActionableAnomalyEvent-actionable-no {
  color: #656565;
}

/* main content */
.PrvActionableAnomalyEvent-content {
  margin: 2.5rem 0 1.5rem 0;
}
.PrvActionableAnomalyEvent-content-1,
.PrvActionableAnomalyEvent-content-2-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 0 10px;
}
.PrvActionableAnomalyEvent-content-1-text,
.PrvActionableAnomalyEvent-content-2-description {
  padding-right: 3rem;
} 
.PrvActionableAnomalyEvent-content-1-text {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 10px;

  /* background-color: #aaa; */
}
/* .PrvActionableAnomalyEvent-text-subcontainer {
  background-color: cyan;
  border: 1px solid purple;
} */
.PrvActionableAnomalyEvent-text-label {
  font-size: 0.7rem;
  line-height: 1.2;
  color: #878787
}
.PrvActionableAnomalyEvent-text-value {
  font-size: 0.8rem;
  /* line-height: 1; */
}
/* SECOND CONTENT CONTAINER */
.PrvActionableAnomalyEvent-content-2 {
  margin-top: 3rem;
}
.PrvActionableAnomalyEvent-content-2-titel {
  margin-bottom: 1rem;
  font-size: 0.95rem;
  font-weight: 400;
}
.PrvActionableAnomalyEvent-content-2-description-body {
  text-align: justify;
}
.PrvActionableAnomalyEvent-content-2-description-parameters, 
.PrvActionableAnomalyEvent-parameters-win-threshold,
.PrvActionableAnomalyEvent-parameters-win-rate-ma {
  margin-top: 1.5rem;
}
.PrvActionableAnomalyEvent-parameters-win-threshold {
  display: grid;
  gap: 0 2rem;
  grid-template-columns: max-content max-content;
}
.PrvActionableAnomalyEvent-parameters-trade-periods-label,
.PrvActionableAnomalyEvent-parameters-win-threshold-label,
.PrvActionableAnomalyEvent-parameters-win-rate-ma-label {
  margin-bottom: 0.25rem;
  font-size: 0.7rem;
  color: #878787
}
.PrvActionableAnomalyEvent-parameters-trade-periods-buttons .ant-radio-button-wrapper,
.PrvActionableAnomalyEvent-parameters-win-threshold-input input,
.PrvActionableAnomalyEvent-parameters-win-rate-ma-buttons .ant-radio-button-wrapper
{
  font-size: 0.9rem;
  font-weight: 300;
}
.PrvActionableAnomalyEvent-parameters-win-threshold-input input {
  color: #1890ff
}
.PrvActionableAnomalyEvent-parameters-win-threshold-price { 
  color: #1890ff;
  line-height: 1.5715;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 2px 7px 0 7px;
  width:fit-content;
  min-width: 88px;

  &:hover {
    cursor: not-allowed;
  }
}