.PeriodicReturnViewerResultItem {
  margin: 0 150px 0rem 0;
  /* background-color: khaki; */
}


/* --------------- HEADER --------------- */
.PeriodicReturnViewerResultItem-header {
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  padding: 0px 0px;
  font-weight: 500;
  border-bottom: 2px solid #878787;
  /* background-color: purple; */
}
.PeriodicReturnViewerResultItem-header-title-info {
  /* display: inline-flex; */
  padding: 0 0.3rem;
  color: #878787;
  font-size: 0.6rem;
  font-weight: 300;
  letter-spacing: 0.5pt;
  transition: 200ms all ease-in-out;
}
.PeriodicReturnViewerResultItem-header-title-info:hover {
  cursor: pointer;
  font-weight: 500;
  color: #1890ff;
}
/* .PeriodicReturnViewerResultItem-header-title-info-icon {
  color: #878787;
  font-size: 0.8rem;
}
.PeriodicReturnViewerResultItem-header-title-info:hover svg {
  color: #1890ff;
} */


.PeriodicReturnViewerResultItem-container {
  width: 100%;
  /* height: 250px; */
  display: grid;
  grid-template-columns: 1fr 1.9fr 1fr;
  min-height: 450px;
  height: 67vh;
}

.PeriodicReturnViewerResultItem-container-details {
  /* background-color: seashell; */
  padding: 5px 10px;
  display: grid;
  gap: 0px 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1.75fr 1fr 1fr 1.5fr 1fr 1fr 1.5fr 1fr 1fr 1.5fr;
  overflow: auto;
  white-space: nowrap;
}

.PeriodicReturnViewerResultItem-details-item.delta {
  grid-column: span 2;
  align-self: unset;
}
.PeriodicReturnViewerResultItem-details-item.delta
  .PeriodicReturnViewerResultItem-details-value {
  display: flex;
  font-size: 1rem;
  font-weight: 400;
}
.PeriodicReturnViewerResultItem-details-value-return {
  color: #1890ff;
}
.PeriodicReturnViewerResultItem-details-value-seperator {
  margin: 0 0.5rem;
  font-weight: 300;
  color: #878787;
}
.PeriodicReturnViewerResultItem-details-value-vola {
  color: #126cbf;
}
.PeriodicReturnViewerResultItem-details-item.delta.return {
  margin: 0.65rem 0 0 0;
}
.PeriodicReturnViewerResultItem-details-label {
  font-weight: 300;
  font-size: 0.7rem;
  line-height: 1.2;
  color: #878787;
}
.PeriodicReturnViewerResultItem-details-value {
  font-weight: 300;
  font-size: 0.8rem;
  padding-bottom: 10px;
}

/* INFO MODAL */
.PeriodicReturnViewer-info-modal {
  height: 75vh;
}
.PeriodicReturnViewer-info-modal .ant-modal-header {
  padding: 1.5rem 2.25rem;
}
.PeriodicReturnViewer-info-modal .ant-modal-body {
  padding: 1.5rem 2.25rem 1.5rem 2.25rem;
  font-size: 1rem;
  font-weight: 200;
}
.PeriodicReturnViewer-info-modal-title {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  font-weight: 400;
}

.PeriodicReturnViewer-info-modal-container {
  display: flex;
  width:100%;
}
.PeriodicReturnViewer-info-modal .info-modal-subcontainer {
  width: 50%
}

.PeriodicReturnViewer-info-modal-address-phone, .PeriodicReturnViewer-info-modal-address-website {
  display: flex;
}
.PeriodicReturnViewer-info-modal .address-label {
  width: 75px
}

.PeriodicReturnViewer-info-modal-address-phone {
  margin-top: 25px;
}
.PeriodicReturnViewer-info-modal-subcontainer-category {
  display: grid;
  height: fit-content;
  grid-template-columns: 75px 1fr;
  grid-template-rows: repeat(2, 1fr);
}
.PeriodicReturnViewer-info-modal .category-label {
  font-weight: 300;
}

.PeriodicReturnViewer-info-modal-description {
  max-height: 30vh;
  overflow-y: auto;
  scroll-margin-left: 0.5rem;
  margin-top: 1.5rem;
  text-align: justify;
  padding-right: 10px;
}

/* Footer Area */
.PeriodicReturnViewer-info-modal-footer {
  display: flex;
  padding-right: 10px;
}
.PeriodicReturnViewer-info-modal-date {
  justify-content: right;
}
.PeriodicReturnViewer-info-modal-date,.PeriodicReturnViewer-info-modal-source {
  width: 50%;
  display: flex;
  margin-top: 1rem;
  font-size: 0.8rem;
  color: #878787
}
.PeriodicReturnViewer-info-modal-date-label, .PeriodicReturnViewer-info-modal-source-label {
  margin-right: 0.5rem;
}
