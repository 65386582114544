.LandingPage,
.LandingPage::before,
.LandingPage::after {
  box-sizing: border-box;
}

.LandingPage {
  height: fit-content;
  width: 100%;
  background-color: #000;
  background-attachment: fixed;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 46px;
  background-position-x: center;
}

.LandingPage-nav {
  position: fixed;
  top: 50vh;
  left: 35px;
  color: #555;
  font-weight: 300;
}
.LandingPage-nav div {
  margin: 15px 0;
  line-height: 1;
  border-left: 1px solid #555;
  padding: 0 0 0 5px;
  transition: all 250ms ease-in-out;
}
.LandingPage-nav div:hover {
  border-left: 1px solid #ddd;
  color: #ddd;
  cursor: pointer;
}

.LandingPage-nav div.current-section {
  color: #1890ff;
  border-left: 2px solid #1890ff;
}

.LandingPage-section-1 {
  width: 100%;
  margin-bottom: 7.5rem;
}

.LandingPage-section-1-container {
  width: 100%;
  height: 90%;
  margin: 0 auto;
}

.LandingPage-section-1-title-main {
  padding: 2rem 0 0 0;
  font-size: clamp(3rem, 3.65vw, 4rem);
  font-weight: 600;
  letter-spacing: 0.15rem;
  text-align: center;
  background: -webkit-linear-gradient(#fff 55%, #666 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  position: absolute;
  top: 2rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.LandingPage-section-1-title-sub {
  color: #fff;
  font-size: 1.3rem;
  font-weight: 300;
  text-align: center;

  position: absolute;
  top: 9.5rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.LandingPage-section-1-container-dial {
  display: flex;
  justify-content: center;
}
.LandingPage-section-1-container-bh-dial {
  width: 70vw;
  height: 90vh;
  margin-top: 1rem;
  margin-left: 15vw;
}
.LandingPage-dial {
  position: absolute;
  left: 15%;
  width: 70vw;
  height: 90vh;
  margin: auto auto;
}

.LandingPage-section-1-blackhole {
  position: absolute;
  margin: auto auto;
  width: 70vw;
  height: 90vh;
  left: 15%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
}

@keyframes blackhole {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 0.65;
  }
  100% {
    transform: scale(1.01);
    -webkit-transform: scale(1.02);
    opacity: 0.45;
  }
}

.LandingPage-section-1-blackhole {
  animation: blackhole 3s ease-in-out infinite alternate;
  -webkit-animation: blackhole 3s ease-in-out infinite alternate;
}

.LandingPage-section-1-container-button {
  margin-top: -8.5vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.LandingPage-button.join {
  font-size: 1.075vw;
  font-weight: 400;
  letter-spacing: 1px;
  color: #ddd;
  background-color: rgba(100, 100, 100, 0.25);
  fill-opacity: 0.1;
  height: 4.5vh;
  width: 20%;
  transition: all 250ms ease-in-out;
}
.LandingPage-button.join:hover {
  /* color: #1890ff; */
  color: #595959;
  background-color: rgba(20, 20, 20, 0.35);
  /* font-weight: 400; */
}

/* 
===================================
SECTION breaking
===================================
*/
.LandingPage-section-breaking {
  height: fit-content;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(4, 4, 4, 0.1) 0%,
    rgba(24, 24, 24, 0.75) 14%,
    rgba(30, 31, 33, 0.75) 35%,
    rgba(36, 39, 42, 0.75) 55%,
    rgba(42, 46, 51, 0.75) 75%,
    rgba(32, 36, 41, 0.55) 85%,
    rgba(12, 13, 14, 0.35) 95%,
    rgba(4, 4, 4, 0.1) 100%
  );
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.LandingPage-section-breaking-container {
  width: 66%;
  margin: 0 auto;
  padding: 7.5rem 0;
  color: #ddd;
}
.LandingPage-section-breaking-heading-line {
  margin-top: 0.35rem;
  width: calc(100% + 50px);
  height: 2px;
  background: linear-gradient(90deg, #ddd 40%, rgba(4, 4, 4, 0.1) 100%);
}
.LandingPage-section-breaking-heading {
  line-height: 1.7;
  font-size: 1.4rem;
  padding-bottom: 0px;
  font-weight: 500;
  width: fit-content;
}

.LandingPage-section-breaking .text-titel {
  font-weight: 500;
}

.LandingPage-section-breaking-text-link {
  margin-top: 1rem;
}

/* 
===================================
SECTION philosophy
===================================
*/
.LandingPage-section-philosophy {
  height: fit-content;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(4, 4, 4, 0.1) 0%,
    rgba(24, 24, 24, 0.75) 14%,
    rgba(30, 31, 33, 0.75) 35%,
    rgba(36, 39, 42, 0.75) 55%,
    rgba(42, 46, 51, 0.75) 75%,
    rgba(32, 36, 41, 0.55) 85%,
    rgba(12, 13, 14, 0.35) 95%,
    rgba(4, 4, 4, 0.1) 100%
  );
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.LandingPage-section-philosophy-container {
  width: 66%;
  margin: 0 auto;
  padding: 7.5rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px 70px;
  color: #ddd;
}
.LandingPage-section-philosophy-grid-item {
  text-align: justify;
  font-weight: 200;
  line-height: 1.7;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.LandingPage-section-philosophy-heading,
.LandingPage-section-philosophy-box-community {
  grid-column: 1 / -1;
}
.LandingPage-section-philosophy-heading {
  font-size: 1.4rem;
  padding-bottom: 0px;
  font-weight: 500;
  width: fit-content;
}
.LandingPage-section-philosophy-heading-line {
  margin-top: 0.35rem;
  width: calc(100% + 50px);
  height: 2px;
  background: linear-gradient(90deg, #ddd 40%, rgba(4, 4, 4, 0.1) 100%);
}

.LandingPage-section-philosophy .text-titel {
  font-weight: 500;
}

.LandingPage-section-philosophy-img {
  color: #000;
  text-align: center;
  margin-top: 25px;
}

.LandingPage-section-philosophy-img img {
  object-fit: cover;
  height: 13.5rem;
  width: 100%;
  border-radius: 2px;
}

/* 
===================================
SECTION philosophy
===================================
*/
.LandingPage-section-team {
  height: fit-content;
  width: 100%;
  background: linear-gradient(0deg,
      rgba(4, 4, 4, 0.1) 0%,
      rgba(24, 24, 24, 0.75) 14%,
      rgba(30, 31, 33, 0.75) 35%,
      rgba(36, 39, 42, 0.75) 55%,
      rgba(42, 46, 51, 0.75) 75%,
      rgba(32, 36, 41, 0.55) 85%,
      rgba(12, 13, 14, 0.35) 95%,
      rgba(4, 4, 4, 0.1) 100%);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.LandingPage-section-team-container {
  width: 66%;
  margin: 0 auto;
  padding: 7.5rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px 70px;
  color: #ddd;
}
.LandingPage-section-team-heading {
  font-size: 1.4rem;
  padding-bottom: 0px;
  font-weight: 500;
  width: fit-content;
}
.LandingPage-section-team-heading-line {
  margin-top: 0.35rem;
  width: calc(100% + 50px);
  height: 2px;
  background: linear-gradient(90deg, #ddd 40%, rgba(4, 4, 4, 0.1) 100%);
}

/* 
===================================
SECTION impressum
===================================
*/

.LandingPage-section-bottom {
  height: fit-content;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}
.LandingPage-section-bottom-container {
  width: 66%;
  margin: 0 auto;
  padding: 6.5rem 0;
  display: grid;
  grid-template-rows: repeat(3, fit-content);
  gap: 20px;
  color: #ddd;
}

.LandingPage-section-bottom-container .grid-item {
  align-self: center;
  margin: 0 auto;
}
.LandingPage-section-bottom-container .grid-item a {
  color: #ddd;
}
.LandingPage-section-bottom-container .grid-item a:hover{
  color: #1890ff;
}

.LandingPage-section-bottom-subcontainer-1,
.LandingPage-section-bottom-subcontainer-2,
.LandingPage-section-bottom-subcontainer-3,
.LandingPage-section-bottom-subcontainer-4 {
  font-weight: 200;
}

.LandingPage-section-bottom-subcontainer-1 svg {
  margin: 0 17px;
  fill: #ddd;

  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
}
.LandingPage-section-bottom-subcontainer-1 svg:hover {
  fill: #1890ff;
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
}
.LandingPage-section-bottom-subcontainer-1 .disabled svg:hover {
  fill: #ddd;
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  cursor: not-allowed;
}

.LandingPage-section-bottom-subcontainer-2 span {
  margin: 0 1rem;
}

.LandingPage-section-bottom-subcontainer-3-text {
  padding-top: 1rem;
  letter-spacing: 1px;
}
.LandingPage-section-bottom-subcontainer-3-text span {
  margin: 0 0.5rem;
  letter-spacing: 0px;
}
.LandingPage-section-bottom-subcontainer-4.grid-item {
  margin-top: 2rem;
}

.LandingPage-section-bottom-subcontainer-4-text {
  text-align: justify;
}
