.PeriodicReturnViewerContent {
  margin-top: 45px;
  width: 100%;
}

/* PARAMETER HEAD */
.PeriodicReturnViewerContent-container-dropdown {
  display: flex;
  align-items: center;
  margin: 40px 0 0 150px;
  font-weight: 300;
}
.PeriodicReturnViewerContent-container-dropdown
  .ant-select-selection.ant-select-selection--single {
  border-radius: 2px;
}
.PeriodicReturnViewerContent .ProductTreeSelect,
.PeriodicReturnViewerContent .PeriodSelect {
  margin-right: 25px;
}
.PeriodicReturnViewerContent-dropdown-period {
  width: 200px;
}
.PeriodicReturnViewerContent-button.submit {
  font-weight: 300;
  border-radius: 2px;
}
.PeriodicReturnViewerContent-datepicker {
  margin-right: 25px;
}
.PeriodicReturnViewerContent-datepicker .ant-picker-input input {
  font-weight: 300;
}
.PeriodicReturnViewerContent-datepicker .ant-picker-input input {
  font-weight: 300;
}
.ant-picker-header-view {
  font-weight: 400;
}

table.ant-picker-content th,
table.ant-picker-content td {
  font-weight: 300 !important;
  /* color: #f00; */
}

/* SPINNER */
.PeriodicReturnViewerContent-Spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 45px - (40px + 32px) - 30px);
}

/* RESULTS */
.PeriodicReturnViewerContent-container-results {
  margin: 4.5vh 0 0px 150px;
}
.PeriodicReturnViewerContent-results-header {
  margin: 4rem 0 2rem 0;
  font-size: 1.5rem;
  font-weight: 500;
}
.PeriodicReturnViewerContent-results-error {
  color: #f00;
  font-weight: 300;
}
