div.ImprintPage {
  display: flex;
  height: fit-content;
  justify-content: center;
  font-weight: 200;
  font-size: 1rem;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.ImprintPage-container {
  margin: auto auto;
  padding: 2rem 5rem;
  width: 66%;
  height: calc(100% - 4rem);
  /* background-color: #fff; */
  background-color: #141414;
  color: #eee;
  opacity: 0.97;
  border-radius: 1px;
  /* overflow-y: scroll; */
}

/* HEADING */
.ImprintPage-container-heading-heading {
  font-size: 1.75rem;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
}

/* CONTAINERS*/
.ImprintPage-container-imprint,
.ImprintPage-container-disclaimer,
.ImprintPage-container-reference {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* CONTAINER IMPRINT */
.ImprintPage-container-imprint-reference {
  margin: 1rem 0;
  font-size: 1.1rem;
  font-weight: 400;
}
.ImprintPage-container-imprint-contact,
.ImprintPage-container-imprint-registration {
  margin-top: 1rem;
  text-decoration: underline;
}

/* CONTAINER DISCLAIMER & REFERENCE*/
.ImprintPage-container-disclaimer-heading {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
}
.ImprintPage-container-disclaimer-content-heading,
.ImprintPage-container-disclaimer-links-heading,
.ImprintPage-container-disclaimer-copyright-heading {
  text-decoration: underline;
  margin: 1.5rem 0 0.5rem 0;
}
.ImprintPage-container-disclaimer-content-text,
.ImprintPage-container-disclaimer-links-text,
.ImprintPage-container-disclaimer-copyright-text {
  font-size: 0.9rem;
  text-align: justify;
}

.ImprintPage-container-reference {
  margin-bottom: 1rem;
}
