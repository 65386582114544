.PeriodicReturnFilterResult {
  margin: 3rem 150px 6rem 0;
}

.PeriodicReturnFilterResult thead.ant-table-thead tr th {
  font-weight: 400;
  font-size: 0.85rem;
}
.PeriodicReturnFilterResult thead.ant-table-thead .ant-table-column-sorters {
  display: flex;
  justify-content: space-between;
}

.PeriodicReturnFilterResult thead.ant-table-thead .ant-table-filter-trigger {
  width: 2rem;
}
.PeriodicReturnFilterResult
  thead.ant-table-thead
  .ant-table-filter-trigger
  svg {
  margin-top: 0.3rem;
}
.PeriodicReturnFilterResult .ant-table-tbody {
  font-size: 0.85rem;
  font-weight: 300;
  cursor: pointer;
}
.PeriodicReturnFilterResult .ant-table-tbody .cell.align-right {
  text-align: right;
}
.PeriodicReturnFilterResult .ant-table-tbody .cell.direction-vertical {
  display: flex;
  flex-direction: column;
}
.PeriodicReturnFilterResult .ant-table-tbody .cell.font-color-green {
  color: #389e0d;
}
.PeriodicReturnFilterResult .ant-table-tbody .cell.font-color-red {
  color: #ff181d;
}
.PeriodicReturnFilterResult .ant-table-tbody .cell.symbol {
  color: #1890ff;
  font-weight: 400;
}
.PeriodicReturnFilterResult .ant-table-tbody .cell.symbol:hover {
  cursor: pointer;
}
.PeriodicReturnFilterResult .ant-table-tbody .cell.delta-log-return {
  /* font-weight: 400; */
}
div.PeriodicReturnFilterResult-search-dropdown input.ant-input {
  color: red;
}
