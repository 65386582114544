/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
					specific style adjustments
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* --- menu (taken out of MainHeader.css --- */
.ant-menu,
.ant-input {
  font-weight: 300;
}

/* --- sider --- */
.ant-layout-sider-children {
  border-right: 1px solid #d9d9d9;
}

/* --- to validate --- */
.ant-menu-horizontal
  > .ant-menu-item:not(.ant-menu-item-selected .ant-menu-item-active) {
  color: #e6e6e6;
}

@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700');
body {
  font-family: 'Kanit', sans-serif !important;
}

/* AVOID THAT TEXT CAN BE SELECTED */
.no-text-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
