.AnomalyTradePerformanceTable {
  margin: 2rem 150px 6rem 0;
  width: 100%
}
.AnomalyTradePerformanceTable-table {
  user-select: none;
}
.AnomalyTradePerformanceTable thead.ant-table-thead tr th {
  font-weight: 400;
  font-size: 0.85rem;
}
.AnomalyTradePerformanceTable .ant-table-tbody {
  font-size: 0.85rem;
  font-weight: 300;
}
.AnomalyTradePerformanceTable .ant-table-tbody .cell.direction-vertical {
  display: flex;
  flex-direction: column;
}

.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="+3b"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="+2b"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="+1b"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="-1b"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="-2b"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="-3b"]
{
  background-color: #f5f5f5
}
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="+3"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="+2"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="+1"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="-1"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="-2"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="-3"]
{
  background-color: #fff;
}

.AnomalyTradePerformanceTable .ant-table-tbody tr:hover td {
  background-color: #e2f1ff;
}