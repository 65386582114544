@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700);
/* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
					specific style adjustments
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * */

/* --- menu (taken out of MainHeader.css --- */
.ant-menu,
.ant-input {
  font-weight: 300;
}

/* --- sider --- */
.ant-layout-sider-children {
  border-right: 1px solid #d9d9d9;
}

/* --- to validate --- */
.ant-menu-horizontal
  > .ant-menu-item:not(.ant-menu-item-selected .ant-menu-item-active) {
  color: #e6e6e6;
}
body {
  font-family: 'Kanit', sans-serif !important;
}

/* AVOID THAT TEXT CAN BE SELECTED */
.no-text-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.BouncerPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
}

.BouncerPage-container-form {
  margin: 5rem auto auto;
  padding: 5px;
  width: 500px;
  background-color: rgb(13, 27, 39);
  /* background-color: #333; */
  opacity: 0.9;
  border-radius: 1px;
}
.BouncerPage-form {
  margin: auto auto;
  padding: 25px;
  padding-bottom: 10px;
}
.BouncerPage .ant-row.ant-form-item {
  margin-bottom: 1rem;
}
.BouncerPage .ant-col.ant-form-item-control-wrapper {
  width: 95%;
}
.BouncerPage .ant-input {
  /* padding: 0 auto; */
  text-align: center;
  font-size: 16px;
  height: 2.1rem;
  background-color: #000;
  color: rgb(227, 242, 255);
  letter-spacing: 1px;
  border-color: rgb(227, 242, 255);
}
.BouncerPage .ant-input:hover {
  background-color: #000;
}
.BouncerPage .ant-form-item-explain {
  font-size: 13px;
  margin: 0.5rem 0 0 0;
  letter-spacing: 1px;
}
.BouncerPage-container-form .ant-form-item-has-error {
  margin-bottom: 0 !important;
}

.BouncerPage .login-form-button {
  margin-top: 1rem;
  width: 100%;
  height: 2.5rem;
  background-color: #000;
  /* color: rgb(227, 242, 255); */
  color: #006dd3 !important;
  /* border: none; */
}
.BouncerPage .login-form-button:hover {
  background-color: rgb(7, 8, 10);
  color: #1890ff !important;
}
.BouncerPage .login-form-button span {
  font-size: 18px;
  /* color: rgb(227, 242, 255); */
  letter-spacing: 1px;
}

.BouncerPage-note {
  padding-bottom: 2rem;
  font-weight: 200;
  color: #99a5b3;
}
/* - - - - - - - - - - MainHeader - - - - - - - - - - */
.MainHeader {
  /* background-color: #1f1f1f; */
  background-color: #141414;
  color: #fff;
  position: fixed;
  width: 100%;
  z-index: 10;
  /* border-bottom: 2px solid #fff; */
  border-bottom: 1px solid #fff;
  font-weight: 300;
}

.MainHeader-logo {
  position: fixed;
  left: 30px;
}

.MainHeader-container {
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 17%;
  width: 66%;
  margin: auto;
  float: right;
}

.MainHeader-menu {
  width: 33.33%;
  padding-left: 0;
  /* color: #bfbfbf; */
}
.MainHeader-menu:hover {
  color: #1890ff;
  cursor: pointer;
}
.MainHeader-menu-icon {
  margin-right: 7px;
}

.MainHeader-comment {
  width: 33.33%;
  text-align: center;
  /* font-size: 0.9rem; */
  /* color: #ffc53d; */
  /* font-style: italic; */
  /* MacroHive logo restyling: color, font-size */
  /* color: #f18f2c; */
  font-size: 0.6rem;
  letter-spacing: 1pt; 
  color: #f8f8f8;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.MainHeader-container-authentication {
  width: 33.33%;
  float: right;
  font-weight: 300;
}
.MainHeader-span-login,
.MainHeader-span-account,
.MainHeader-span-logout {
  /* color: #bfbfbf; */
  color: #fff;
  /* color: #096dd9; */
  float: right;
}
.MainHeader-span-login:hover,
.MainHeader-span-account:hover,
.MainHeader-span-logout:hover {
  color: #595959;
  cursor: not-allowed;
}
.MainHeader-icon-login {
  margin-right: 7px;
  font-size: 14px;
}
.MainHeader-icon-account,
.MainHeader-icon-logout {
  margin-right: 7px;
  font-size: 15px;
}
.MainHeader-span-account {
  margin-right: 15px;
}

/* - - - - - - - - - - MainHeader - SubMenu - - - - - - - - - - */
.MainHeader-submenu-backdrop {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 47px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1;
}
.MainHeader-submenu {
  width: 100vw;
  height: 200px;
  background-color: rgba(20, 20, 20, 0.97);
  position: fixed;
  top: 47px;
  left: 0;
  z-index: -1;
  display: flex;
  align-items: center;
}
.MainHeader-submenu-container {
  width: 66%;
  height: 150px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px;
  gap: 10px;
}
.MainHeader-submenu-entry {
  align-self: center;
  padding-left: 15px;
  line-height: 40px;
  transition: all 150ms ease-in-out;
}
.MainHeader-submenu-entry a {
  color: unset;
  cursor: unset;
}
.MainHeader-submenu-entry.entry-active:hover, .MainHeader-submenu-entry.entry-active a:hover {
  cursor: pointer;
  color: #1890ff;
  background-color: rgba(31, 31, 31, 0.6);
}

.MainHeader-submenu-entry.entry-inactive {
  color: #595959;
}
.MainHeader-submenu-entry-public {
  margin-left: 7px;
  font-size: 10px;
  /* color: #ffc53d; */
  color: #bf1216;
  font-style: italic;
}

.ErrorPage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.ErrorPage-container {
  margin-top: 25vh;
}

h1.ErrorPage-message {
  font-size: 30px;
}

.ErrorPage-subcontainer-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.LandingPage,
.LandingPage::before,
.LandingPage::after {
  box-sizing: border-box;
}

.LandingPage {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  background-color: #000;
  background-attachment: fixed;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: 46px;
  background-position-x: center;
}

.LandingPage-nav {
  position: fixed;
  top: 50vh;
  left: 35px;
  color: #555;
  font-weight: 300;
}
.LandingPage-nav div {
  margin: 15px 0;
  line-height: 1;
  border-left: 1px solid #555;
  padding: 0 0 0 5px;
  transition: all 250ms ease-in-out;
}
.LandingPage-nav div:hover {
  border-left: 1px solid #ddd;
  color: #ddd;
  cursor: pointer;
}

.LandingPage-nav div.current-section {
  color: #1890ff;
  border-left: 2px solid #1890ff;
}

.LandingPage-section-1 {
  width: 100%;
  margin-bottom: 7.5rem;
}

.LandingPage-section-1-container {
  width: 100%;
  height: 90%;
  margin: 0 auto;
}

.LandingPage-section-1-title-main {
  padding: 2rem 0 0 0;
  font-size: clamp(3rem, 3.65vw, 4rem);
  font-weight: 600;
  letter-spacing: 0.15rem;
  text-align: center;
  background: -webkit-linear-gradient(#fff 55%, #666 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  position: absolute;
  top: 2rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.LandingPage-section-1-title-sub {
  color: #fff;
  font-size: 1.3rem;
  font-weight: 300;
  text-align: center;

  position: absolute;
  top: 9.5rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.LandingPage-section-1-container-dial {
  display: flex;
  justify-content: center;
}
.LandingPage-section-1-container-bh-dial {
  width: 70vw;
  height: 90vh;
  margin-top: 1rem;
  margin-left: 15vw;
}
.LandingPage-dial {
  position: absolute;
  left: 15%;
  width: 70vw;
  height: 90vh;
  margin: auto auto;
}

.LandingPage-section-1-blackhole {
  position: absolute;
  margin: auto auto;
  width: 70vw;
  height: 90vh;
  left: 15%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: center;
}

@-webkit-keyframes blackhole {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 0.65;
  }
  100% {
    transform: scale(1.01);
    -webkit-transform: scale(1.02);
    opacity: 0.45;
  }
}

@keyframes blackhole {
  0% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 0.65;
  }
  100% {
    transform: scale(1.01);
    -webkit-transform: scale(1.02);
    opacity: 0.45;
  }
}

.LandingPage-section-1-blackhole {
  animation: blackhole 3s ease-in-out infinite alternate;
  -webkit-animation: blackhole 3s ease-in-out infinite alternate;
}

.LandingPage-section-1-container-button {
  margin-top: -8.5vh;
  width: 100%;
  display: flex;
  justify-content: center;
}

.LandingPage-button.join {
  font-size: 1.075vw;
  font-weight: 400;
  letter-spacing: 1px;
  color: #ddd;
  background-color: rgba(100, 100, 100, 0.25);
  fill-opacity: 0.1;
  height: 4.5vh;
  width: 20%;
  transition: all 250ms ease-in-out;
}
.LandingPage-button.join:hover {
  /* color: #1890ff; */
  color: #595959;
  background-color: rgba(20, 20, 20, 0.35);
  /* font-weight: 400; */
}

/* 
===================================
SECTION breaking
===================================
*/
.LandingPage-section-breaking {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(4, 4, 4, 0.1) 0%,
    rgba(24, 24, 24, 0.75) 14%,
    rgba(30, 31, 33, 0.75) 35%,
    rgba(36, 39, 42, 0.75) 55%,
    rgba(42, 46, 51, 0.75) 75%,
    rgba(32, 36, 41, 0.55) 85%,
    rgba(12, 13, 14, 0.35) 95%,
    rgba(4, 4, 4, 0.1) 100%
  );
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}
.LandingPage-section-breaking-container {
  width: 66%;
  margin: 0 auto;
  padding: 7.5rem 0;
  color: #ddd;
}
.LandingPage-section-breaking-heading-line {
  margin-top: 0.35rem;
  width: calc(100% + 50px);
  height: 2px;
  background: linear-gradient(90deg, #ddd 40%, rgba(4, 4, 4, 0.1) 100%);
}
.LandingPage-section-breaking-heading {
  line-height: 1.7;
  font-size: 1.4rem;
  padding-bottom: 0px;
  font-weight: 500;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.LandingPage-section-breaking .text-titel {
  font-weight: 500;
}

.LandingPage-section-breaking-text-link {
  margin-top: 1rem;
}

/* 
===================================
SECTION philosophy
===================================
*/
.LandingPage-section-philosophy {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgba(4, 4, 4, 0.1) 0%,
    rgba(24, 24, 24, 0.75) 14%,
    rgba(30, 31, 33, 0.75) 35%,
    rgba(36, 39, 42, 0.75) 55%,
    rgba(42, 46, 51, 0.75) 75%,
    rgba(32, 36, 41, 0.55) 85%,
    rgba(12, 13, 14, 0.35) 95%,
    rgba(4, 4, 4, 0.1) 100%
  );
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.LandingPage-section-philosophy-container {
  width: 66%;
  margin: 0 auto;
  padding: 7.5rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px 70px;
  gap: 50px 70px;
  color: #ddd;
}
.LandingPage-section-philosophy-grid-item {
  text-align: justify;
  font-weight: 200;
  line-height: 1.7;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.LandingPage-section-philosophy-heading,
.LandingPage-section-philosophy-box-community {
  grid-column: 1 / -1;
}
.LandingPage-section-philosophy-heading {
  font-size: 1.4rem;
  padding-bottom: 0px;
  font-weight: 500;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.LandingPage-section-philosophy-heading-line {
  margin-top: 0.35rem;
  width: calc(100% + 50px);
  height: 2px;
  background: linear-gradient(90deg, #ddd 40%, rgba(4, 4, 4, 0.1) 100%);
}

.LandingPage-section-philosophy .text-titel {
  font-weight: 500;
}

.LandingPage-section-philosophy-img {
  color: #000;
  text-align: center;
  margin-top: 25px;
}

.LandingPage-section-philosophy-img img {
  object-fit: cover;
  height: 13.5rem;
  width: 100%;
  border-radius: 2px;
}

/* 
===================================
SECTION philosophy
===================================
*/
.LandingPage-section-team {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  background: linear-gradient(0deg,
      rgba(4, 4, 4, 0.1) 0%,
      rgba(24, 24, 24, 0.75) 14%,
      rgba(30, 31, 33, 0.75) 35%,
      rgba(36, 39, 42, 0.75) 55%,
      rgba(42, 46, 51, 0.75) 75%,
      rgba(32, 36, 41, 0.55) 85%,
      rgba(12, 13, 14, 0.35) 95%,
      rgba(4, 4, 4, 0.1) 100%);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}

.LandingPage-section-team-container {
  width: 66%;
  margin: 0 auto;
  padding: 7.5rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 50px 70px;
  gap: 50px 70px;
  color: #ddd;
}
.LandingPage-section-team-heading {
  font-size: 1.4rem;
  padding-bottom: 0px;
  font-weight: 500;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.LandingPage-section-team-heading-line {
  margin-top: 0.35rem;
  width: calc(100% + 50px);
  height: 2px;
  background: linear-gradient(90deg, #ddd 40%, rgba(4, 4, 4, 0.1) 100%);
}

/* 
===================================
SECTION impressum
===================================
*/

.LandingPage-section-bottom {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.75);
}
.LandingPage-section-bottom-container {
  width: 66%;
  margin: 0 auto;
  padding: 6.5rem 0;
  display: grid;
  grid-template-rows: repeat(3, -webkit-fit-content);
  grid-template-rows: repeat(3, fit-content);
  grid-gap: 20px;
  gap: 20px;
  color: #ddd;
}

.LandingPage-section-bottom-container .grid-item {
  align-self: center;
  margin: 0 auto;
}
.LandingPage-section-bottom-container .grid-item a {
  color: #ddd;
}
.LandingPage-section-bottom-container .grid-item a:hover{
  color: #1890ff;
}

.LandingPage-section-bottom-subcontainer-1,
.LandingPage-section-bottom-subcontainer-2,
.LandingPage-section-bottom-subcontainer-3,
.LandingPage-section-bottom-subcontainer-4 {
  font-weight: 200;
}

.LandingPage-section-bottom-subcontainer-1 svg {
  margin: 0 17px;
  fill: #ddd;

  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  transition: all 150ms ease-in-out;
  -webkit-transition: all 150ms ease-in-out;
}
.LandingPage-section-bottom-subcontainer-1 svg:hover {
  fill: #1890ff;
  transform: scale(1.5);
  -webkit-transform: scale(1.5);
}
.LandingPage-section-bottom-subcontainer-1 .disabled svg:hover {
  fill: #ddd;
  transform: scale(1.2);
  -webkit-transform: scale(1.2);
  cursor: not-allowed;
}

.LandingPage-section-bottom-subcontainer-2 span {
  margin: 0 1rem;
}

.LandingPage-section-bottom-subcontainer-3-text {
  padding-top: 1rem;
  letter-spacing: 1px;
}
.LandingPage-section-bottom-subcontainer-3-text span {
  margin: 0 0.5rem;
  letter-spacing: 0px;
}
.LandingPage-section-bottom-subcontainer-4.grid-item {
  margin-top: 2rem;
}

.LandingPage-section-bottom-subcontainer-4-text {
  text-align: justify;
}

/* - - - - - - - - - - CONTAINER | WINGS - - - - - - - - - - */
#market-views-container,
#education-container,
#analytic-toolkit-container,
#community-container {
  cursor: pointer;
}
#market-views-title,
#education-title,
#analytic-toolkit-title,
#community-title {
  font-weight: 400;
}

/* - - - - - - - - - - DIAL TEXTS - - - - - - - - - - */
/* svg.LandingPage-dial text[id*='dial-text'] tspan {
  font-weight: 400;
} */

#dial-text-market-views:hover,
#dial-text-anomalies tspan:hover,
#dial-text-volatility tspan:hover,
#dial-text-flows tspan:hover,
#dial-text-gamma tspan:hover,
#dial-text-spreads tspan:hover,
#dial-text-seasonality tspan:hover,
#dial-text-portfolio tspan:hover,
#dial-text-timing tspan:hover,
#dial-text-economy tspan:hover,
#dial-text-sectors tspan:hover,
#dial-text-calendar tspan:hover,
#dial-text-cot tspan:hover {
  transition: all 250ms ease-in-out;
  -webkit-transition: all 250ms ease-in-out;
}

/* active */
#dial-text-market-views:hover,
#dial-text-anomalies tspan:hover
#dial-text-seasonality tspan:hover,
#dial-text-flows tspan:hover,
#dial-text-volatility tspan:hover,
#dial-text-gamma tspan:hover
{
  cursor: pointer;
  fill: #1890ff;
  /* font-size: 0.34rem; */
}

/* not active yet */
#dial-text-timing tspan:hover,
#dial-text-portfolio tspan:hover,
#dial-text-economy tspan:hover,
#dial-text-sectors tspan:hover,
#dial-text-calendar tspan:hover,
#dial-text-cot tspan:hover,
#dial-text-spreads tspan:hover
{
  cursor: not-allowed;
  fill: #333;
  /* font-size: 0.34rem; */
}

.LandingPage .BreakingSection {
  width: 100%;
  font-weight: 200;
  background: none;
}
.LandingPage .BreakingSection-main-container {
  width: 100%;
  margin: 0 auto;
  padding: 50px 0 0 0;
  border-top: none;
}


.LandingPage .BreakingSection-title {
  font-size: 1.35rem;
  font-weight: 400;
}
.LandingPage .BreakingSection-category {
  margin-bottom: 1rem;
}
.LandingPage .BreakingSection-icon {
  margin-left: 1.00rem;;
}

.LandingPage .BreakingSection-body-container {
  margin-top: 1.5rem;
  display: grid;
  grid-gap: 0 4rem;
  gap: 0 4rem;
  grid-template-columns: 2fr 1fr;
}
@media screen and (max-width: 1400px){
  .LandingPage .BreakingSection-body-container {
    margin-top: 1.5rem;
    display: grid;
    grid-gap: 2rem;
    gap: 2rem;
    grid-template-columns: 1fr;
  }
  .LandingPage .BreakingSection-media-container {
    margin: 0 auto;
  }
}

.LandingPage .BreakingSection-text-container {
  text-align: justify;
}
.LandingPage .BreakingSection-main-link, .LandingPage .BreakingSection-sec-link {
  margin-top: 1rem;;
}
.LandingPage .BreakingSection-sec-link a {
  -webkit-filter: brightness(100%);
          filter: brightness(100%);
  transition: all 0.3s ease-in-out;
}
.LandingPage .BreakingSection-sec-link a:hover {
  -webkit-filter: brightness(150%);
          filter: brightness(150%);
}

.LandingPage .BreakingSection-media-container {
  padding-top: 7px;
}
.LandingPage .BreakingSection-image {
  max-width: 640px;
}



div.ImprintPage {
  display: flex;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  justify-content: center;
  font-weight: 200;
  font-size: 1rem;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}

.ImprintPage-container {
  margin: auto auto;
  padding: 2rem 5rem;
  width: 66%;
  height: calc(100% - 4rem);
  /* background-color: #fff; */
  background-color: #141414;
  color: #eee;
  opacity: 0.97;
  border-radius: 1px;
  /* overflow-y: scroll; */
}

/* HEADING */
.ImprintPage-container-heading-heading {
  font-size: 1.75rem;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
  color: #fff;
}

/* CONTAINERS*/
.ImprintPage-container-imprint,
.ImprintPage-container-disclaimer,
.ImprintPage-container-reference {
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/* CONTAINER IMPRINT */
.ImprintPage-container-imprint-reference {
  margin: 1rem 0;
  font-size: 1.1rem;
  font-weight: 400;
}
.ImprintPage-container-imprint-contact,
.ImprintPage-container-imprint-registration {
  margin-top: 1rem;
  text-decoration: underline;
}

/* CONTAINER DISCLAIMER & REFERENCE*/
.ImprintPage-container-disclaimer-heading {
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
}
.ImprintPage-container-disclaimer-content-heading,
.ImprintPage-container-disclaimer-links-heading,
.ImprintPage-container-disclaimer-copyright-heading {
  text-decoration: underline;
  margin: 1.5rem 0 0.5rem 0;
}
.ImprintPage-container-disclaimer-content-text,
.ImprintPage-container-disclaimer-links-text,
.ImprintPage-container-disclaimer-copyright-text {
  font-size: 0.9rem;
  text-align: justify;
}

.ImprintPage-container-reference {
  margin-bottom: 1rem;
}

.Main-content {
  margin-top: 46px;
  overflow-y: hidden;
}

/* .AnomaliesHeadMenu .ant-menu-item {
  padding-right: 15px !important;
  padding-left: 15px !important;
} */

.AnomaliesHeadMenu-header {
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: #404040;
  border-bottom: 1px solid #595959;
  box-shadow: 0px 1px 1px #737373;
  padding: 0 0 0 50px;
}

.AnomaliesHeadMenu-header .loading:before {
  content: attr(data-text);
  position: absolute;
  top: auto;
  left: auto;
  color: #fff;
  overflow: hidden;
  animation: loading 2s linear infinite alternate;
  -webkit-animation: loading 2s linear infinite alternate;
  -moz-animation: loading 2s linear infinite alternate;
  -o-animation: loading 2s linear infinite alternate;
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 86%;
  }
}
@-webkit-keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 86%;
  }
}

/* HEADER INFO */
.AnomaliesHeadMenu-container-info {
  display: flex;
  height: inherit;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  float: right;
  align-items: center;
  margin-right: 20px;
  /* margin-right: 0px; */
  /* padding: 0 10px;
  transition: 0.3s all cubic-bezier(0.645, 0.045, 0.355, 1) */
}
/* .AnomaliesHeadMenu-container-info:hover {
  background-color: #1890ff;
} */

/* Info using i-Icon */
.AnomaliesHeadMenu-container-info svg {
  fill: #e6e6e6;
  transition: 300ms all ease-in-out;
}
.AnomaliesHeadMenu-container-info:hover {
  cursor: pointer;
}
.AnomaliesHeadMenu-container-info:hover svg {
  cursor: pointer;
  fill: #1890ff;
}

/* Info using Thorsten */
.AnomaliesHeadMenu-container-info-icon-thorsten {
  transition: 300ms all ease-in-out;
}
.AnomaliesHeadMenu-container-info-label {
  transition: 200ms all ease-in-out;
}
.AnomaliesHeadMenu-container-info-label {
  margin-right: 5px;
  color:#e6e6e6;
  font-weight: 200;
}
/* .AnomaliesHeadMenu-container-info-label:hover, */
.AnomaliesHeadMenu-container-info:hover .AnomaliesHeadMenu-container-info-label {
  color: #fff;
  font-weight: 300;
  letter-spacing: 0.2pt;
}

/* .AnomaliesHeadMenu-container-info-icon-thorsten:hover, */
.AnomaliesHeadMenu-container-info:hover .AnomaliesHeadMenu-container-info-icon-thorsten {
  width: 27px;
}

/* MODAL */
.AnomaliesHeadMenu-modal-header {
  font-weight: 400;
}
.AnomaliesHeadMenu-modal-text {
  font-weight: 300;
}
.CategorySelect-dropdown {
  width: 300px;
}

.ant-select-dropdown-menu-item-group-title {
  font-weight: 300;
}

.PeriodSelect-dropdown {
  width: 200px;
}

.ant-select-selection-search input {
  font-weight: 300;
}

.SpinnerWithText {
  text-align: center;
}
.SpinnerWithText-icon {
  margin-bottom: 0.5rem;
}
.SpinnerWithText-text {
  font-weight: 300;
}

.PeriodicReturnFilterResult {
  margin: 3rem 150px 6rem 0;
}

.PeriodicReturnFilterResult thead.ant-table-thead tr th {
  font-weight: 400;
  font-size: 0.85rem;
}
.PeriodicReturnFilterResult thead.ant-table-thead .ant-table-column-sorters {
  display: flex;
  justify-content: space-between;
}

.PeriodicReturnFilterResult thead.ant-table-thead .ant-table-filter-trigger {
  width: 2rem;
}
.PeriodicReturnFilterResult
  thead.ant-table-thead
  .ant-table-filter-trigger
  svg {
  margin-top: 0.3rem;
}
.PeriodicReturnFilterResult .ant-table-tbody {
  font-size: 0.85rem;
  font-weight: 300;
  cursor: pointer;
}
.PeriodicReturnFilterResult .ant-table-tbody .cell.align-right {
  text-align: right;
}
.PeriodicReturnFilterResult .ant-table-tbody .cell.direction-vertical {
  display: flex;
  flex-direction: column;
}
.PeriodicReturnFilterResult .ant-table-tbody .cell.font-color-green {
  color: #389e0d;
}
.PeriodicReturnFilterResult .ant-table-tbody .cell.font-color-red {
  color: #ff181d;
}
.PeriodicReturnFilterResult .ant-table-tbody .cell.symbol {
  color: #1890ff;
  font-weight: 400;
}
.PeriodicReturnFilterResult .ant-table-tbody .cell.symbol:hover {
  cursor: pointer;
}
.PeriodicReturnFilterResult .ant-table-tbody .cell.delta-log-return {
  /* font-weight: 400; */
}
div.PeriodicReturnFilterResult-search-dropdown input.ant-input {
  color: red;
}

.PeriodicReturnFilterContent {
  margin-top: 45px;
  width: 100%;
}

/* PARAMETER HEAD */
.PeriodicReturnFilterContent-container-dropdown
  .ant-select-selection.ant-select-selection--single {
  border-radius: 2px;
}
.PeriodicReturnFilterContent-container-dropdown {
  display: flex;
  align-items: center;
  margin: 40px 0 0 150px;
  font-weight: 300;
}
.PeriodicReturnFilterContent .CategorySelect,
.PeriodicReturnFilterContent .PeriodSelect,
.PeriodicReturnFilterContent-dropdown-threshold {
  margin-right: 25px;
}
.PeriodicReturnFilterContent-dropdown-category {
  width: 200px;
}
.PeriodicReturnFilterContent-dropdown-threshold {
  width: 200px;
}
.PeriodicReturnFilterContent-button.submit {
  font-weight: 300;
  border-radius: 2px;
}
.PeriodicReturnFilterContent-note {
  margin-left: 25px;
  font-size: 0.8rem;
}

/* SPINNER */
.PeriodicReturnFilterContent-Spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 70vh;
}

/* RESULTS */
.PeriodicReturnFilterContent-container-results {
  margin: 40px 0 60px 150px;
}
.PeriodicReturnFilterContent-results-header {
  margin: 4rem 150px 2rem 0;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: justify;
}

.PeriodicReturnViewerResultItem {
  margin: 0 150px 0rem 0;
  /* background-color: khaki; */
}


/* --------------- HEADER --------------- */
.PeriodicReturnViewerResultItem-header {
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  padding: 0px 0px;
  font-weight: 500;
  border-bottom: 2px solid #878787;
  /* background-color: purple; */
}
.PeriodicReturnViewerResultItem-header-title-info {
  /* display: inline-flex; */
  padding: 0 0.3rem;
  color: #878787;
  font-size: 0.6rem;
  font-weight: 300;
  letter-spacing: 0.5pt;
  transition: 200ms all ease-in-out;
}
.PeriodicReturnViewerResultItem-header-title-info:hover {
  cursor: pointer;
  font-weight: 500;
  color: #1890ff;
}
/* .PeriodicReturnViewerResultItem-header-title-info-icon {
  color: #878787;
  font-size: 0.8rem;
}
.PeriodicReturnViewerResultItem-header-title-info:hover svg {
  color: #1890ff;
} */


.PeriodicReturnViewerResultItem-container {
  width: 100%;
  /* height: 250px; */
  display: grid;
  grid-template-columns: 1fr 1.9fr 1fr;
  min-height: 450px;
  height: 67vh;
}

.PeriodicReturnViewerResultItem-container-details {
  /* background-color: seashell; */
  padding: 5px 10px;
  display: grid;
  grid-gap: 0px 10px;
  gap: 0px 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1.75fr 1fr 1fr 1.5fr 1fr 1fr 1.5fr 1fr 1fr 1.5fr;
  overflow: auto;
  white-space: nowrap;
}

.PeriodicReturnViewerResultItem-details-item.delta {
  grid-column: span 2;
  align-self: unset;
}
.PeriodicReturnViewerResultItem-details-item.delta
  .PeriodicReturnViewerResultItem-details-value {
  display: flex;
  font-size: 1rem;
  font-weight: 400;
}
.PeriodicReturnViewerResultItem-details-value-return {
  color: #1890ff;
}
.PeriodicReturnViewerResultItem-details-value-seperator {
  margin: 0 0.5rem;
  font-weight: 300;
  color: #878787;
}
.PeriodicReturnViewerResultItem-details-value-vola {
  color: #126cbf;
}
.PeriodicReturnViewerResultItem-details-item.delta.return {
  margin: 0.65rem 0 0 0;
}
.PeriodicReturnViewerResultItem-details-label {
  font-weight: 300;
  font-size: 0.7rem;
  line-height: 1.2;
  color: #878787;
}
.PeriodicReturnViewerResultItem-details-value {
  font-weight: 300;
  font-size: 0.8rem;
  padding-bottom: 10px;
}

/* INFO MODAL */
.PeriodicReturnViewer-info-modal {
  height: 75vh;
}
.PeriodicReturnViewer-info-modal .ant-modal-header {
  padding: 1.5rem 2.25rem;
}
.PeriodicReturnViewer-info-modal .ant-modal-body {
  padding: 1.5rem 2.25rem 1.5rem 2.25rem;
  font-size: 1rem;
  font-weight: 200;
}
.PeriodicReturnViewer-info-modal-title {
  margin-bottom: 1rem;
  font-size: 1.1rem;
  font-weight: 400;
}

.PeriodicReturnViewer-info-modal-container {
  display: flex;
  width:100%;
}
.PeriodicReturnViewer-info-modal .info-modal-subcontainer {
  width: 50%
}

.PeriodicReturnViewer-info-modal-address-phone, .PeriodicReturnViewer-info-modal-address-website {
  display: flex;
}
.PeriodicReturnViewer-info-modal .address-label {
  width: 75px
}

.PeriodicReturnViewer-info-modal-address-phone {
  margin-top: 25px;
}
.PeriodicReturnViewer-info-modal-subcontainer-category {
  display: grid;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  grid-template-columns: 75px 1fr;
  grid-template-rows: repeat(2, 1fr);
}
.PeriodicReturnViewer-info-modal .category-label {
  font-weight: 300;
}

.PeriodicReturnViewer-info-modal-description {
  max-height: 30vh;
  overflow-y: auto;
  scroll-margin-left: 0.5rem;
  margin-top: 1.5rem;
  text-align: justify;
  padding-right: 10px;
}

/* Footer Area */
.PeriodicReturnViewer-info-modal-footer {
  display: flex;
  padding-right: 10px;
}
.PeriodicReturnViewer-info-modal-date {
  justify-content: right;
}
.PeriodicReturnViewer-info-modal-date,.PeriodicReturnViewer-info-modal-source {
  width: 50%;
  display: flex;
  margin-top: 1rem;
  font-size: 0.8rem;
  color: #878787
}
.PeriodicReturnViewer-info-modal-date-label, .PeriodicReturnViewer-info-modal-source-label {
  margin-right: 0.5rem;
}

.AnomalyTradePerformanceTable {
  margin: 2rem 150px 6rem 0;
  width: 100%
}
.AnomalyTradePerformanceTable-table {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.AnomalyTradePerformanceTable thead.ant-table-thead tr th {
  font-weight: 400;
  font-size: 0.85rem;
}
.AnomalyTradePerformanceTable .ant-table-tbody {
  font-size: 0.85rem;
  font-weight: 300;
}
.AnomalyTradePerformanceTable .ant-table-tbody .cell.direction-vertical {
  display: flex;
  flex-direction: column;
}

.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="+3b"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="+2b"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="+1b"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="-1b"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="-2b"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="-3b"]
{
  background-color: #f5f5f5
}
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="+3"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="+2"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="+1"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="-1"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="-2"], 
.AnomalyTradePerformanceTable .ant-table-tbody tr[data-row-key="-3"]
{
  background-color: #fff;
}

.AnomalyTradePerformanceTable .ant-table-tbody tr:hover td {
  background-color: #e2f1ff;
}
.PrvActionableAnomalyEvent {
  margin-top: 1.5rem;
}
.PrvActionableAnomalyEvent-heading {
  font-size: 1.1rem;
  font-weight: 400;
  /* background-color: #ddd; */
}
.PrvActionableAnomalyEvent-actionable-yes {
  color: #1890ff;
}
.PrvActionableAnomalyEvent-actionable-no {
  color: #656565;
}

/* main content */
.PrvActionableAnomalyEvent-content {
  margin: 2.5rem 0 1.5rem 0;
}
.PrvActionableAnomalyEvent-content-1,
.PrvActionableAnomalyEvent-content-2-container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 0 10px;
  gap: 0 10px;
}
.PrvActionableAnomalyEvent-content-1-text,
.PrvActionableAnomalyEvent-content-2-description {
  padding-right: 3rem;
} 
.PrvActionableAnomalyEvent-content-1-text {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 10px;
  gap: 10px 10px;

  /* background-color: #aaa; */
}
/* .PrvActionableAnomalyEvent-text-subcontainer {
  background-color: cyan;
  border: 1px solid purple;
} */
.PrvActionableAnomalyEvent-text-label {
  font-size: 0.7rem;
  line-height: 1.2;
  color: #878787
}
.PrvActionableAnomalyEvent-text-value {
  font-size: 0.8rem;
  /* line-height: 1; */
}
/* SECOND CONTENT CONTAINER */
.PrvActionableAnomalyEvent-content-2 {
  margin-top: 3rem;
}
.PrvActionableAnomalyEvent-content-2-titel {
  margin-bottom: 1rem;
  font-size: 0.95rem;
  font-weight: 400;
}
.PrvActionableAnomalyEvent-content-2-description-body {
  text-align: justify;
}
.PrvActionableAnomalyEvent-content-2-description-parameters, 
.PrvActionableAnomalyEvent-parameters-win-threshold,
.PrvActionableAnomalyEvent-parameters-win-rate-ma {
  margin-top: 1.5rem;
}
.PrvActionableAnomalyEvent-parameters-win-threshold {
  display: grid;
  grid-gap: 0 2rem;
  gap: 0 2rem;
  grid-template-columns: -webkit-max-content -webkit-max-content;
  grid-template-columns: max-content max-content;
}
.PrvActionableAnomalyEvent-parameters-trade-periods-label,
.PrvActionableAnomalyEvent-parameters-win-threshold-label,
.PrvActionableAnomalyEvent-parameters-win-rate-ma-label {
  margin-bottom: 0.25rem;
  font-size: 0.7rem;
  color: #878787
}
.PrvActionableAnomalyEvent-parameters-trade-periods-buttons .ant-radio-button-wrapper,
.PrvActionableAnomalyEvent-parameters-win-threshold-input input,
.PrvActionableAnomalyEvent-parameters-win-rate-ma-buttons .ant-radio-button-wrapper
{
  font-size: 0.9rem;
  font-weight: 300;
}
.PrvActionableAnomalyEvent-parameters-win-threshold-input input {
  color: #1890ff
}
.PrvActionableAnomalyEvent-parameters-win-threshold-price { 
  color: #1890ff;
  line-height: 1.5715;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 2px 7px 0 7px;
  width:-webkit-fit-content;
  width:-moz-fit-content;
  width:fit-content;
  min-width: 88px;

  &:hover {
    cursor: not-allowed;
  }
}
.PrvAnomalyTradePerformanceBlock {
  margin: 3rem 150px calc(50px + 2.5rem) 0;
  /* background-color: aquamarine; */
  border-top: 1px solid #ddd;
  font-size: 0.9rem;
  font-weight: 300;
}
.PrvAnomalyTradePerformanceBlock-heading {
  margin-top: 3rem;
  font-size: 1.3rem;
  font-weight: 500;
  /* background-color: #ddd; */
}
.PrvAnomalyTradePerformanceBlock-subheading {
  margin-top: 1rem;
  font-size: 1.1rem;
  font-weight: 400;
  /* background-color: #ddd; */
}
.PrvAnomalyTradePerformanceBlock-explanation {
  margin-top: 1rem;
  text-align: justify;
  /* background-color: #ddd; */
}

.ant-select-selection-search input {
  font-weight: 300;
}

.ProductTreeSelect-treeselect {
  width: 450px;
}
.ProductTreeSelect-treeselect-dropdown {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.ProductTreeSelect-treenode-parent {
  padding: 4px 0 !important;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1rem !important;
}
.ProductTreeSelect-treenode-element span.ant-select-tree-title {
  font-size: 0.9rem;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.65);
}
.ProductTreeSelect-treenode-element {
  padding: 0px !important;
  line-height: 1 !important;
}

.PeriodicReturnViewerContent {
  margin-top: 45px;
  width: 100%;
}

/* PARAMETER HEAD */
.PeriodicReturnViewerContent-container-dropdown {
  display: flex;
  align-items: center;
  margin: 40px 0 0 150px;
  font-weight: 300;
}
.PeriodicReturnViewerContent-container-dropdown
  .ant-select-selection.ant-select-selection--single {
  border-radius: 2px;
}
.PeriodicReturnViewerContent .ProductTreeSelect,
.PeriodicReturnViewerContent .PeriodSelect {
  margin-right: 25px;
}
.PeriodicReturnViewerContent-dropdown-period {
  width: 200px;
}
.PeriodicReturnViewerContent-button.submit {
  font-weight: 300;
  border-radius: 2px;
}
.PeriodicReturnViewerContent-datepicker {
  margin-right: 25px;
}
.PeriodicReturnViewerContent-datepicker .ant-picker-input input {
  font-weight: 300;
}
.PeriodicReturnViewerContent-datepicker .ant-picker-input input {
  font-weight: 300;
}
.ant-picker-header-view {
  font-weight: 400;
}

table.ant-picker-content th,
table.ant-picker-content td {
  font-weight: 300 !important;
  /* color: #f00; */
}

/* SPINNER */
.PeriodicReturnViewerContent-Spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 45px - (40px + 32px) - 30px);
}

/* RESULTS */
.PeriodicReturnViewerContent-container-results {
  margin: 4.5vh 0 0px 150px;
}
.PeriodicReturnViewerContent-results-header {
  margin: 4rem 0 2rem 0;
  font-size: 1.5rem;
  font-weight: 500;
}
.PeriodicReturnViewerContent-results-error {
  color: #f00;
  font-weight: 300;
}

.AnomaliesFooter {
  width: 100%;
  height: 30px;
  padding: 2px;
  position: fixed;
  bottom: 0;
  z-index: 10;
  color: #999999;
  background-color: #404040;
  /* background-color: #141414; */
  text-align: center;
  border-top: 1px solid #d9d9d9;
}

.AnomaliesFooter-text {
  font-weight: 300;
}

/* .SeasonalityHeadMenu .ant-menu-item {
  padding-right: 15px !important;
  padding-left: 15px !important;
} */

.SeasonalityHeadMenu-header {
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: #404040;
  border-bottom: 1px solid #595959;
  box-shadow: 0px 1px 1px #737373;
  padding: 0 0 0 50px;
}

.SeasonalityHeadMenu-header .loading:before {
  content: attr(data-text);
  position: absolute;
  top: auto;
  left: auto;
  color: #fff;
  overflow: hidden;
  animation: loading 2s linear infinite alternate;
  -webkit-animation: loading 2s linear infinite alternate;
  -moz-animation: loading 2s linear infinite alternate;
  -o-animation: loading 2s linear infinite alternate;
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 86%;
  }
}
@-webkit-keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 86%;
  }
}

/* HEADER INFO */
.SeasonalityHeadMenu-container-info {
  display: flex;
  height: inherit;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  float: right;
  align-items: center;
  margin-right: 20px;
  /* margin-right: 0px; */
  /* padding: 0 10px;
  transition: 0.3s all cubic-bezier(0.645, 0.045, 0.355, 1) */
}
/* .SeasonalityHeadMenu-container-info:hover {
  background-color: #1890ff;
} */

/* Info using i-Icon */
.SeasonalityHeadMenu-container-info svg {
  fill: #e6e6e6;
  transition: 300ms all ease-in-out;
}
.SeasonalityHeadMenu-container-info:hover {
  cursor: pointer;
}
.SeasonalityHeadMenu-container-info:hover svg {
  cursor: pointer;
  fill: #1890ff;
}

/* Info using Thorsten */
.SeasonalityHeadMenu-container-info-icon-thorsten {
  transition: 300ms all ease-in-out;
}
.SeasonalityHeadMenu-container-info-label {
  transition: 200ms all ease-in-out;
}
.SeasonalityHeadMenu-container-info-label {
  margin-right: 5px;
  color:#e6e6e6;
  font-weight: 200;
}
/* .SeasonalityHeadMenu-container-info-label:hover, */
.SeasonalityHeadMenu-container-info:hover .SeasonalityHeadMenu-container-info-label {
  color: #fff;
  font-weight: 300;
  letter-spacing: 0.2pt;
}

/* .SeasonalityHeadMenu-container-info-icon-thorsten:hover, */
.SeasonalityHeadMenu-container-info:hover .SeasonalityHeadMenu-container-info-icon-thorsten {
  width: 27px;
}

/* MODAL */
.SeasonalityHeadMenu-modal-header {
  font-weight: 400;
}
.SeasonalityHeadMenu-modal-text {
  font-weight: 300;
}
.SeasonalityFooter {
  width: 100%;
  height: 30px;
  padding: 2px;
  position: fixed;
  bottom: 0;
  z-index: 10;
  color: #999999;
  background-color: #404040;
  /* background-color: #141414; */
  text-align: center;
  border-top: 1px solid #d9d9d9;
}

.SeasonalityFooter-text {
  font-weight: 300;
}

.SeasonalityViewerResultItem-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #878787;
}

/* --------------- HEADER --------------- */
.SeasonalityViewerResultItem-header {
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  padding: 0px 0px;
  font-weight: 500;
  border-bottom: 2px solid #878787;
  /* background-color: purple; */
}
.SeasonalityViewerResultItem-header-title-info {
  padding: 0 0.3rem;
  color: #878787;
  font-size: 0.6rem;
  font-weight: 300;
  letter-spacing: 0.5pt;
  transition: 200ms all ease-in-out;
}
.SeasonalityViewerResultItem-header-title-info:hover {
  cursor: pointer;
  font-weight: 500;
  color: #1890ff;
}


/* --------------- MAIN RESULTITEM CONTAINER --------------- */
/* CONTAINER 1 */
.SeasonalityViewerResultItem-subcontainer-title {
  margin-top: 1rem;
  font-size: 1.1rem;
}
.SeasonalityViewerResultItem-subcontainer-1-result-container,
.SeasonalityViewerResultItem-subcontainer-2-result-container {
  margin-bottom: 1.5rem;

  display: grid;
  grid-gap: 0px 10px;
  gap: 0px 10px;
  grid-template-columns: 1fr 4fr;
}
.SeasonalityViewerResultItem-subcontainer-2-result-container {
  margin-bottom: 3rem;
}
@media screen and (max-height: 900px) {
  .SeasonalityViewerResultItem-subcontainer-2-result-container {
    margin-bottom: 2rem;
  } 
}

/* CONTAINER 1 - SUB CONTAINER DATA */
.SeasonalityViewerResultItem-subcontainer-1-result-container-data {
  display: grid;
  grid-gap: 0px 10px;
  gap: 0px 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 1.25fr 1fr 1.25fr 1fr 1fr;
  overflow: auto;
  white-space: nowrap;
  
  padding: 5px 10px;
  margin-top: calc(1rem + 24px + 1rem);
  font-size: 0.9rem;
}
.SeasonalityViewerResultItem-subcontainer-1-result-container-data .SeasonalityViewerResultItem-details-item:nth-of-type(3) {
  grid-column: span 2;
}
.SeasonalityViewerResultItem-details-item-best-sections {
  grid-column: span 2;
}
.SeasonalityViewerResultItem-details-label {
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.2;
  color: #878787;
}
.SeasonalityViewerResultItem-details-value {
  font-weight: 300;
  font-size: 0.85rem;
  /* padding-bottom: 10px; */
}
/* CONTAINER 1 - SUB CONTAINER CHART */
.SeasonalityViewerResultItem-button-group-allLines {
  display: flex;
  justify-content: center;
  margin-top: 0.25rem;
  font-weight: 300;
}
.SeasonalityViewerResultItem-button-group-allLines .ant-radio-button-wrapper {
  font-size: 0.9rem;
  line-height: 22px;
  height: 24px;
  padding: 0 15px;
}
.SeasonalityViewerResultItem-container-lineChart {
  margin-top: 1rem;
}

/* CONTAINER 2 - SUB CONTAINER DATA */
.SeasonalityViewerResultItem-subcontainer-2-result-container-data {
  display: grid;
  grid-gap: 0px 10px;
  gap: 0px 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1.5fr repeat(3,1fr);
  overflow: auto;
  white-space: nowrap;
  
  padding: 5px 10px;
  font-size: 0.9rem;
}

.SeasonalityViewerResultItem-subcontainer-2-result-container-data .SeasonalityViewerResultItem-details-item:nth-of-type(5) {
  grid-column: span 2;
}
/* CONTAINER 2 - SUB CONTAINER DATA */
.SeasonalityViewerContent {
  margin-top: 45px;
  width: 100%;
}

/* SPINNER */
.SeasonalityViewerContent-Spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 45px - (40px + 32px) - 30px);
}

/* PARAMETER HEAD CONTAINER*/
.SeasonalityViewerContent-container-dropdown {
  display: flex;
  align-items: center;
  margin: 40px 0 0 150px;
  font-weight: 300;
}
.SeasonalityViewerContent-container-dropdown
  .ant-select-selection.ant-select-selection--single {
  border-radius: 2px;
}
.SeasonalityViewerContent .ProductTreeSelect {
  margin-right: 25px;
}
.SeasonalityViewerContent-button.submit {
  font-weight: 300;
  border-radius: 2px;
}


/* CONTAINER RESULT*/
.SeasonalityViewerContent-container-result {
  margin: 4.5vh 150px 0px 150px;
}
/* ERROR */
.SeasonalityViewerContent-error {
  color: #ff181d;
  font-weight: 300;
}
