.LandingPage .BreakingSection {
  width: 100%;
  font-weight: 200;
  background: none;
}
.LandingPage .BreakingSection-main-container {
  width: 100%;
  margin: 0 auto;
  padding: 50px 0 0 0;
  border-top: none;
}


.LandingPage .BreakingSection-title {
  font-size: 1.35rem;
  font-weight: 400;
}
.LandingPage .BreakingSection-category {
  margin-bottom: 1rem;
}
.LandingPage .BreakingSection-icon {
  margin-left: 1.00rem;;
}

.LandingPage .BreakingSection-body-container {
  margin-top: 1.5rem;
  display: grid;
  gap: 0 4rem;
  grid-template-columns: 2fr 1fr;
}
@media screen and (max-width: 1400px){
  .LandingPage .BreakingSection-body-container {
    margin-top: 1.5rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: 1fr;
  }
  .LandingPage .BreakingSection-media-container {
    margin: 0 auto;
  }
}

.LandingPage .BreakingSection-text-container {
  text-align: justify;
}
.LandingPage .BreakingSection-main-link, .LandingPage .BreakingSection-sec-link {
  margin-top: 1rem;;
}
.LandingPage .BreakingSection-sec-link a {
  filter: brightness(100%);
  transition: all 0.3s ease-in-out;
}
.LandingPage .BreakingSection-sec-link a:hover {
  filter: brightness(150%);
}

.LandingPage .BreakingSection-media-container {
  padding-top: 7px;
}
.LandingPage .BreakingSection-image {
  max-width: 640px;
}


