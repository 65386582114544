/* .AnomaliesHeadMenu .ant-menu-item {
  padding-right: 15px !important;
  padding-left: 15px !important;
} */

.AnomaliesHeadMenu-header {
  position: fixed;
  width: 100%;
  z-index: 10;
  background-color: #404040;
  border-bottom: 1px solid #595959;
  box-shadow: 0px 1px 1px #737373;
  padding: 0 0 0 50px;
}

.AnomaliesHeadMenu-header .loading:before {
  content: attr(data-text);
  position: absolute;
  top: auto;
  left: auto;
  color: #fff;
  overflow: hidden;
  animation: loading 2s linear infinite alternate;
  -webkit-animation: loading 2s linear infinite alternate;
  -moz-animation: loading 2s linear infinite alternate;
  -o-animation: loading 2s linear infinite alternate;
}

@keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 86%;
  }
}
@-webkit-keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 86%;
  }
}
@-moz-keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 86%;
  }
}
@-o-keyframes loading {
  0% {
    width: 0%;
  }
  100% {
    width: 86%;
  }
}

/* HEADER INFO */
.AnomaliesHeadMenu-container-info {
  display: flex;
  height: inherit;
  width: fit-content;
  float: right;
  align-items: center;
  margin-right: 20px;
  /* margin-right: 0px; */
  /* padding: 0 10px;
  transition: 0.3s all cubic-bezier(0.645, 0.045, 0.355, 1) */
}
/* .AnomaliesHeadMenu-container-info:hover {
  background-color: #1890ff;
} */

/* Info using i-Icon */
.AnomaliesHeadMenu-container-info svg {
  fill: #e6e6e6;
  transition: 300ms all ease-in-out;
}
.AnomaliesHeadMenu-container-info:hover {
  cursor: pointer;
}
.AnomaliesHeadMenu-container-info:hover svg {
  cursor: pointer;
  fill: #1890ff;
}

/* Info using Thorsten */
.AnomaliesHeadMenu-container-info-icon-thorsten {
  transition: 300ms all ease-in-out;
}
.AnomaliesHeadMenu-container-info-label {
  transition: 200ms all ease-in-out;
}
.AnomaliesHeadMenu-container-info-label {
  margin-right: 5px;
  color:#e6e6e6;
  font-weight: 200;
}
/* .AnomaliesHeadMenu-container-info-label:hover, */
.AnomaliesHeadMenu-container-info:hover .AnomaliesHeadMenu-container-info-label {
  color: #fff;
  font-weight: 300;
  letter-spacing: 0.2pt;
}

/* .AnomaliesHeadMenu-container-info-icon-thorsten:hover, */
.AnomaliesHeadMenu-container-info:hover .AnomaliesHeadMenu-container-info-icon-thorsten {
  width: 27px;
}

/* MODAL */
.AnomaliesHeadMenu-modal-header {
  font-weight: 400;
}
.AnomaliesHeadMenu-modal-text {
  font-weight: 300;
}