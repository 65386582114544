.SeasonalityViewerResultItem-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #878787;
}

/* --------------- HEADER --------------- */
.SeasonalityViewerResultItem-header {
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  padding: 0px 0px;
  font-weight: 500;
  border-bottom: 2px solid #878787;
  /* background-color: purple; */
}
.SeasonalityViewerResultItem-header-title-info {
  padding: 0 0.3rem;
  color: #878787;
  font-size: 0.6rem;
  font-weight: 300;
  letter-spacing: 0.5pt;
  transition: 200ms all ease-in-out;
}
.SeasonalityViewerResultItem-header-title-info:hover {
  cursor: pointer;
  font-weight: 500;
  color: #1890ff;
}


/* --------------- MAIN RESULTITEM CONTAINER --------------- */
/* CONTAINER 1 */
.SeasonalityViewerResultItem-subcontainer-title {
  margin-top: 1rem;
  font-size: 1.1rem;
}
.SeasonalityViewerResultItem-subcontainer-1-result-container,
.SeasonalityViewerResultItem-subcontainer-2-result-container {
  margin-bottom: 1.5rem;

  display: grid;
  gap: 0px 10px;
  grid-template-columns: 1fr 4fr;
}
.SeasonalityViewerResultItem-subcontainer-2-result-container {
  margin-bottom: 3rem;
}
@media screen and (max-height: 900px) {
  .SeasonalityViewerResultItem-subcontainer-2-result-container {
    margin-bottom: 2rem;
  } 
}

/* CONTAINER 1 - SUB CONTAINER DATA */
.SeasonalityViewerResultItem-subcontainer-1-result-container-data {
  display: grid;
  gap: 0px 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 1.25fr 1fr 1.25fr 1fr 1fr;
  overflow: auto;
  white-space: nowrap;
  
  padding: 5px 10px;
  margin-top: calc(1rem + 24px + 1rem);
  font-size: 0.9rem;
}
.SeasonalityViewerResultItem-subcontainer-1-result-container-data .SeasonalityViewerResultItem-details-item:nth-of-type(3) {
  grid-column: span 2;
}
.SeasonalityViewerResultItem-details-item-best-sections {
  grid-column: span 2;
}
.SeasonalityViewerResultItem-details-label {
  font-weight: 300;
  font-size: 0.75rem;
  line-height: 1.2;
  color: #878787;
}
.SeasonalityViewerResultItem-details-value {
  font-weight: 300;
  font-size: 0.85rem;
  /* padding-bottom: 10px; */
}
/* CONTAINER 1 - SUB CONTAINER CHART */
.SeasonalityViewerResultItem-button-group-allLines {
  display: flex;
  justify-content: center;
  margin-top: 0.25rem;
  font-weight: 300;
}
.SeasonalityViewerResultItem-button-group-allLines .ant-radio-button-wrapper {
  font-size: 0.9rem;
  line-height: 22px;
  height: 24px;
  padding: 0 15px;
}
.SeasonalityViewerResultItem-container-lineChart {
  margin-top: 1rem;
}

/* CONTAINER 2 - SUB CONTAINER DATA */
.SeasonalityViewerResultItem-subcontainer-2-result-container-data {
  display: grid;
  gap: 0px 10px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1.5fr repeat(3,1fr);
  overflow: auto;
  white-space: nowrap;
  
  padding: 5px 10px;
  font-size: 0.9rem;
}

.SeasonalityViewerResultItem-subcontainer-2-result-container-data .SeasonalityViewerResultItem-details-item:nth-of-type(5) {
  grid-column: span 2;
}
/* CONTAINER 2 - SUB CONTAINER DATA */