/* - - - - - - - - - - MainHeader - - - - - - - - - - */
.MainHeader {
  /* background-color: #1f1f1f; */
  background-color: #141414;
  color: #fff;
  position: fixed;
  width: 100%;
  z-index: 10;
  /* border-bottom: 2px solid #fff; */
  border-bottom: 1px solid #fff;
  font-weight: 300;
}

.MainHeader-logo {
  position: fixed;
  left: 30px;
}

.MainHeader-container {
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 17%;
  width: 66%;
  margin: auto;
  float: right;
}

.MainHeader-menu {
  width: 33.33%;
  padding-left: 0;
  /* color: #bfbfbf; */
}
.MainHeader-menu:hover {
  color: #1890ff;
  cursor: pointer;
}
.MainHeader-menu-icon {
  margin-right: 7px;
}

.MainHeader-comment {
  width: 33.33%;
  text-align: center;
  /* font-size: 0.9rem; */
  /* color: #ffc53d; */
  /* font-style: italic; */
  /* MacroHive logo restyling: color, font-size */
  /* color: #f18f2c; */
  font-size: 0.6rem;
  letter-spacing: 1pt; 
  color: #f8f8f8;
  user-select: none;
}

.MainHeader-container-authentication {
  width: 33.33%;
  float: right;
  font-weight: 300;
}
.MainHeader-span-login,
.MainHeader-span-account,
.MainHeader-span-logout {
  /* color: #bfbfbf; */
  color: #fff;
  /* color: #096dd9; */
  float: right;
}
.MainHeader-span-login:hover,
.MainHeader-span-account:hover,
.MainHeader-span-logout:hover {
  color: #595959;
  cursor: not-allowed;
}
.MainHeader-icon-login {
  margin-right: 7px;
  font-size: 14px;
}
.MainHeader-icon-account,
.MainHeader-icon-logout {
  margin-right: 7px;
  font-size: 15px;
}
.MainHeader-span-account {
  margin-right: 15px;
}

/* - - - - - - - - - - MainHeader - SubMenu - - - - - - - - - - */
.MainHeader-submenu-backdrop {
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 47px;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: -1;
}
.MainHeader-submenu {
  width: 100vw;
  height: 200px;
  background-color: rgba(20, 20, 20, 0.97);
  position: fixed;
  top: 47px;
  left: 0;
  z-index: -1;
  display: flex;
  align-items: center;
}
.MainHeader-submenu-container {
  width: 66%;
  height: 150px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 10px;
}
.MainHeader-submenu-entry {
  align-self: center;
  padding-left: 15px;
  line-height: 40px;
  transition: all 150ms ease-in-out;
}
.MainHeader-submenu-entry a {
  color: unset;
  cursor: unset;
}
.MainHeader-submenu-entry.entry-active:hover, .MainHeader-submenu-entry.entry-active a:hover {
  cursor: pointer;
  color: #1890ff;
  background-color: rgba(31, 31, 31, 0.6);
}

.MainHeader-submenu-entry.entry-inactive {
  color: #595959;
}
.MainHeader-submenu-entry-public {
  margin-left: 7px;
  font-size: 10px;
  /* color: #ffc53d; */
  color: #bf1216;
  font-style: italic;
}
