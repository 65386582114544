.SeasonalityViewerContent {
  margin-top: 45px;
  width: 100%;
}

/* SPINNER */
.SeasonalityViewerContent-Spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 45px - (40px + 32px) - 30px);
}

/* PARAMETER HEAD CONTAINER*/
.SeasonalityViewerContent-container-dropdown {
  display: flex;
  align-items: center;
  margin: 40px 0 0 150px;
  font-weight: 300;
}
.SeasonalityViewerContent-container-dropdown
  .ant-select-selection.ant-select-selection--single {
  border-radius: 2px;
}
.SeasonalityViewerContent .ProductTreeSelect {
  margin-right: 25px;
}
.SeasonalityViewerContent-button.submit {
  font-weight: 300;
  border-radius: 2px;
}


/* CONTAINER RESULT*/
.SeasonalityViewerContent-container-result {
  margin: 4.5vh 150px 0px 150px;
}
/* ERROR */
.SeasonalityViewerContent-error {
  color: #ff181d;
  font-weight: 300;
}