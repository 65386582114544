.PrvAnomalyTradePerformanceBlock {
  margin: 3rem 150px calc(50px + 2.5rem) 0;
  /* background-color: aquamarine; */
  border-top: 1px solid #ddd;
  font-size: 0.9rem;
  font-weight: 300;
}
.PrvAnomalyTradePerformanceBlock-heading {
  margin-top: 3rem;
  font-size: 1.3rem;
  font-weight: 500;
  /* background-color: #ddd; */
}
.PrvAnomalyTradePerformanceBlock-subheading {
  margin-top: 1rem;
  font-size: 1.1rem;
  font-weight: 400;
  /* background-color: #ddd; */
}
.PrvAnomalyTradePerformanceBlock-explanation {
  margin-top: 1rem;
  text-align: justify;
  /* background-color: #ddd; */
}
