.PeriodicReturnFilterContent {
  margin-top: 45px;
  width: 100%;
}

/* PARAMETER HEAD */
.PeriodicReturnFilterContent-container-dropdown
  .ant-select-selection.ant-select-selection--single {
  border-radius: 2px;
}
.PeriodicReturnFilterContent-container-dropdown {
  display: flex;
  align-items: center;
  margin: 40px 0 0 150px;
  font-weight: 300;
}
.PeriodicReturnFilterContent .CategorySelect,
.PeriodicReturnFilterContent .PeriodSelect,
.PeriodicReturnFilterContent-dropdown-threshold {
  margin-right: 25px;
}
.PeriodicReturnFilterContent-dropdown-category {
  width: 200px;
}
.PeriodicReturnFilterContent-dropdown-threshold {
  width: 200px;
}
.PeriodicReturnFilterContent-button.submit {
  font-weight: 300;
  border-radius: 2px;
}
.PeriodicReturnFilterContent-note {
  margin-left: 25px;
  font-size: 0.8rem;
}

/* SPINNER */
.PeriodicReturnFilterContent-Spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 70vh;
}

/* RESULTS */
.PeriodicReturnFilterContent-container-results {
  margin: 40px 0 60px 150px;
}
.PeriodicReturnFilterContent-results-header {
  margin: 4rem 150px 2rem 0;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: justify;
}
