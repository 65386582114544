.ant-select-selection-search input {
  font-weight: 300;
}

.ProductTreeSelect-treeselect {
  width: 450px;
}
.ProductTreeSelect-treeselect-dropdown {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.ProductTreeSelect-treenode-parent {
  padding: 4px 0 !important;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1rem !important;
}
.ProductTreeSelect-treenode-element span.ant-select-tree-title {
  font-size: 0.9rem;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.65);
}
.ProductTreeSelect-treenode-element {
  padding: 0px !important;
  line-height: 1 !important;
}
