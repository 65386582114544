.ErrorPage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.ErrorPage-container {
  margin-top: 25vh;
}

h1.ErrorPage-message {
  font-size: 30px;
}

.ErrorPage-subcontainer-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
