.BouncerPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-position: center;
}

.BouncerPage-container-form {
  margin: 5rem auto auto;
  padding: 5px;
  width: 500px;
  background-color: rgb(13, 27, 39);
  /* background-color: #333; */
  opacity: 0.9;
  border-radius: 1px;
}
.BouncerPage-form {
  margin: auto auto;
  padding: 25px;
  padding-bottom: 10px;
}
.BouncerPage .ant-row.ant-form-item {
  margin-bottom: 1rem;
}
.BouncerPage .ant-col.ant-form-item-control-wrapper {
  width: 95%;
}
.BouncerPage .ant-input {
  /* padding: 0 auto; */
  text-align: center;
  font-size: 16px;
  height: 2.1rem;
  background-color: #000;
  color: rgb(227, 242, 255);
  letter-spacing: 1px;
  border-color: rgb(227, 242, 255);
}
.BouncerPage .ant-input:hover {
  background-color: #000;
}
.BouncerPage .ant-form-item-explain {
  font-size: 13px;
  margin: 0.5rem 0 0 0;
  letter-spacing: 1px;
}
.BouncerPage-container-form .ant-form-item-has-error {
  margin-bottom: 0 !important;
}

.BouncerPage .login-form-button {
  margin-top: 1rem;
  width: 100%;
  height: 2.5rem;
  background-color: #000;
  /* color: rgb(227, 242, 255); */
  color: #006dd3 !important;
  /* border: none; */
}
.BouncerPage .login-form-button:hover {
  background-color: rgb(7, 8, 10);
  color: #1890ff !important;
}
.BouncerPage .login-form-button span {
  font-size: 18px;
  /* color: rgb(227, 242, 255); */
  letter-spacing: 1px;
}

.BouncerPage-note {
  padding-bottom: 2rem;
  font-weight: 200;
  color: #99a5b3;
}